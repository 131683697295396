"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.XummSdkJwt = exports.XummSdk = void 0;
const debug_1 = require("debug");
const Meta_1 = require("./Meta");
const Storage_1 = require("./Storage");
const Payload_1 = require("./Payload");
const xApp_1 = require("./xApp");
const Push_1 = require("./Push");
const JwtUserdata_1 = require("./JwtUserdata");
const log = debug_1.debug('xumm-sdk');
class XummSdk {
    constructor(apiKey, apiSecret) {
        log('Constructed');
        this.Meta = new Meta_1.Meta(apiKey || this.getEnv('XUMM_APIKEY'), apiSecret || this.getEnv('XUMM_APISECRET'));
        this.storage = new Storage_1.Storage(this.Meta);
        this.payload = new Payload_1.Payload(this.Meta);
        this.jwtUserdata = new JwtUserdata_1.JwtUserdata(this.Meta);
        this.Push = new Push_1.Push(this.Meta);
        this.xApp = new xApp_1.xApp(this.Meta);
        this.Meta._inject(this);
        return this;
    }
    getEnv(arg) {
        let value = '';
        try {
            /* Deno */ // @ts-ignore
            /* Deno */ value = typeof Deno !== 'undefined' ? (Deno.env.get(arg) || '') : '';
            /* Node */ value = (process === null || process === void 0 ? void 0 : process.env[arg]) || '';
        }
        catch (_e) {
            // Couldn't load .env
        }
        return value;
    }
    /**
     * Proxy methods to Meta class below
     */
    ping() {
        return this.Meta.ping();
    }
    getCuratedAssets() {
        return this.Meta.getCuratedAssets();
    }
    getRates(currencyCode) {
        return this.Meta.getRates(currencyCode);
    }
    getKycStatus(userTokenOrAccount) {
        return this.Meta.getKycStatus(userTokenOrAccount);
    }
    getTransaction(txHash) {
        return this.Meta.getTransaction(txHash);
    }
    verifyUserTokens(userTokens) {
        return this.Meta.verifyUserTokens(userTokens);
    }
    verifyUserToken(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const tokenResults = yield this.Meta.verifyUserTokens([token]);
            return Array.isArray(tokenResults) && tokenResults.length === 1
                ? tokenResults[0]
                : null;
        });
    }
    setEndpoint(endpoint) {
        return this.Meta.setEndpoint(endpoint);
    }
    caught(error) {
        throw error;
    }
}
exports.XummSdk = XummSdk;
class XummSdkJwt extends XummSdk {
    constructor(apiKeyOrJwt, ott, options) {
        var _a, _b, _c, _d, _f, _g, _h, _j;
        let _ott = String(ott || '').trim().toLowerCase();
        const isRawJwt = apiKeyOrJwt.length !== 36;
        /**
         * xAppToken from URL to param if not explicitly provided
         */
        if (!isRawJwt) {
            if (typeof ott === 'undefined'
                && typeof window !== 'undefined'
                && typeof window.URLSearchParams !== 'undefined') {
                const urlSearchParams = new window.URLSearchParams(((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.search) || '');
                for (const pair of urlSearchParams.entries()) {
                    if (pair[0] === 'xAppToken') {
                        _ott = pair[1].toLowerCase().trim();
                    }
                }
                if (_ott === '' && !(options === null || options === void 0 ? void 0 : options.store) && !(options === null || options === void 0 ? void 0 : options.noAutoRetrieve)) {
                    // Check if we have something in history
                    if (typeof ((_b = window === null || window === void 0 ? void 0 : window.localStorage) === null || _b === void 0 ? void 0 : _b['XummSdkJwt']) === 'string') {
                        try {
                            const localStorageJwtData = (_d = (_c = window === null || window === void 0 ? void 0 : window.localStorage) === null || _c === void 0 ? void 0 : _c['XummSdkJwt']) === null || _d === void 0 ? void 0 : _d.split(':');
                            const localStorageJwt = JSON.parse((_f = localStorageJwtData === null || localStorageJwtData === void 0 ? void 0 : localStorageJwtData.slice(1)) === null || _f === void 0 ? void 0 : _f.join(':'));
                            if (localStorageJwt === null || localStorageJwt === void 0 ? void 0 : localStorageJwt.jwt) {
                                const jwtContents = JSON.parse(atob((_g = localStorageJwt.jwt.split('.')) === null || _g === void 0 ? void 0 : _g[1]));
                                if (jwtContents === null || jwtContents === void 0 ? void 0 : jwtContents.exp) {
                                    const validForSec = (jwtContents === null || jwtContents === void 0 ? void 0 : jwtContents.exp) - Math.floor((new Date()).getTime() / 1000);
                                    console.log('Restoring OTT ' + (localStorageJwtData === null || localStorageJwtData === void 0 ? void 0 : localStorageJwtData[0]));
                                    if (validForSec > 60 * 60) {
                                        _ott = localStorageJwtData === null || localStorageJwtData === void 0 ? void 0 : localStorageJwtData[0];
                                    }
                                    else {
                                        console.log('Skip restore: not valid for one more hour');
                                    }
                                }
                            }
                        }
                        catch (e) {
                            console.log('JWT Restore Error', e);
                        }
                    }
                }
            }
        }
        super(apiKeyOrJwt, !isRawJwt && _ott !== ''
            ? 'xApp:OneTimeToken:' + _ott
            : 'RAWJWT:' + apiKeyOrJwt);
        this.resolve = (ottData) => {
            log('OTT data resolved', ottData);
        };
        this.reject = (error) => {
            log('OTT data rejected', error.message);
        };
        this.ottResolved = isRawJwt
            ? Promise.resolve()
            : new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        if (options === null || options === void 0 ? void 0 : options.fatalHandler) {
            this.fatalHandler = options.fatalHandler;
        }
        this.store = {
            get(uuid) {
                var _a;
                log('[JwtStore] » Builtin JWT store GET');
                // Default store: localStorage
                if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
                    if (typeof window.localStorage['XummSdkJwt'] === 'string') {
                        const lsOttData = window.localStorage['XummSdkJwt'].split(':');
                        if (lsOttData[0] === uuid) {
                            // Restore from memory
                            log('Restoring OTT from localStorage:', uuid);
                            try {
                                return JSON.parse(lsOttData.slice(1).join(':'));
                            }
                            catch (e) {
                                log('Error restoring OTT Data (JWT) from localStorage', (_a = e) === null || _a === void 0 ? void 0 : _a.message);
                            }
                        }
                    }
                }
            },
            set(uuid, ottData) {
                log('[JwtStore] » Builtin JWT store SET', uuid);
                // Default store: localStorage
                if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
                    window.localStorage['XummSdkJwt'] = uuid + ':' + JSON.stringify(ottData);
                }
            }
        };
        if ((_h = options === null || options === void 0 ? void 0 : options.store) === null || _h === void 0 ? void 0 : _h.get) {
            this.store.get = options.store.get;
        }
        if ((_j = options === null || options === void 0 ? void 0 : options.store) === null || _j === void 0 ? void 0 : _j.set) {
            this.store.set = options.store.set;
        }
        if (isRawJwt) {
            this.reject(new Error('Not in OTT flow: in raw JWT (OAuth2-like) flow'));
            log('Using JWT (Raw, OAuth2) flow');
        }
        else {
            log('Using JWT (xApp) flow');
        }
    }
    _jwtStore(invoker, persistJwt) {
        if (invoker && (invoker === null || invoker === void 0 ? void 0 : invoker.constructor) === Meta_1.Meta) {
            return {
                get: uuid => {
                    var _a;
                    log('[JwtStore] Proxy GET');
                    return (_a = this.store) === null || _a === void 0 ? void 0 : _a.get(uuid);
                },
                set: (uuid, ottData) => {
                    var _a;
                    log('[JwtStore] Proxy SET');
                    this.resolve(ottData.ott);
                    persistJwt(ottData.jwt);
                    this.jwt = ottData.jwt;
                    return (_a = this.store) === null || _a === void 0 ? void 0 : _a.set(uuid, ottData);
                }
            };
        }
        throw new Error('Invalid _jwtStore invoker');
    }
    getOttData() {
        return __awaiter(this, void 0, void 0, function* () {
            const resolved = yield this.ottResolved;
            if (resolved) {
                return resolved;
            }
            throw new Error('Called getOttData on a non OTT-JWT flow');
        });
    }
    getJwt() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ottResolved;
            return this.jwt;
        });
    }
    caught(error) {
        this.reject(error);
    }
}
exports.XummSdkJwt = XummSdkJwt;
