import React, {useEffect, useState } from "react"
import {CgSpinnerTwo} from "react-icons/cg"



/**
 * xumm link handling for external links must be handled by the app
 * sdk, so we need some way to pass the link to the app as well as 
 * just dispay it as a link for webapp users.
 * 
 * links is a list of objects with the following structure:
 * [{ 'title': 'title', 'url': 'url' }]
 * 
 * @param {*} param0 
 */
export const ExternalLinksViewer = ({links, xumm, title="Important Links"}) => {

  const [runtime, setRuntime] = useState();

  useEffect(() => {
    xumm?.then((xummSDK) => {
      setRuntime(xummSDK.runtime);
    });
  }, [xumm]);

  /**
   * this handles the xApp and webapp cases for external links
   * 
   * @param {*} url 
   */
  let handleClickedLink = (url) => {
    if (runtime.xapp) {
      console.log("clicked link in xApp", url);

      xumm?.then((xummSDK) => {
        xummSDK.xapp.openBrowser({ url: url })
        .then(d => {
          // d (returned value) can be Error or return data:
          console.log('openBrowser response:', d instanceof Error ? d.message : d)
        })
        .catch(e => console.log('Error:', e.message));
      });

    } else if (runtime.browser && !runtime.xapp) {
      console.log("clicked link in Web browser", url);
      window.open(url, "_blank");
    }
  };

  let renderUrls = (links) => {
    return links.map((key, index) => (
        <li className="break-words" key={index}>
            <div onClick={()=>handleClickedLink(key.url)} className="text-xs font-bold text-blue-300 
              hover:underline cursor-pointer">{key.title}</div> 
        </li>
    ));
  };

  return (
    <div className="flex flex-col">
        <ul className="mb-0 list-none space-y-1">
          {renderUrls(links)}
        </ul>
    </div>
  );
};



export const Alert = ({ background, text, children }) => {
  return (
    <div className={`p-2 flex flex-row rounded ${background} ${text} w-full`} role="alert">
      {children}
    </div>
  );
};

export const HelpAlert = ({ children, helpLink }) => {
  return (
    <Alert background="bg-slate-100" text="text-slate-800">
        <div className="flex flex-row justify-end">
            <div>{children}</div>
        </div>      
    </Alert>
  );
};

export const Modal = ({ show, children }) => {
  return (
    <div
      className={`modal ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export const Badge = ({ variant, children }) => {
  return <span className={`badge badge-${variant}`}>{children}</span>;
};

export const Spinner = () => {
  return (
    <>
    <div className="inline-block w-9 h-9 border-2 rounded-full" role="status">
        <CgSpinnerTwo className="w-8 h-8 animate-spin text-yellow-400"/>
    </div>
    </>
  )
};