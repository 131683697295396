import React, {useState, useEffect} from "react"

import { useNavigate } from "react-router-dom";

import { AccountService } from "../service/AccountService";

import { getTypeFromRuntime } from "../utils";
import xLogo from "../assets/xlogo.png";
import loginXummLogo from "../assets/login_xumm.png";

const whitepaperUrl = "https://ibisx.io/whitepaper.pdf";

const Header = ({
    xumm=null,
    runtime=null,
    children,
  }) => {

    /**
     * we want the app to behave the same depending on if
     * this a XAPP or a webapp
     */
    const [account, setAccount] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [identity, setIdentity] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        console.log("Header.js useEffect");
        xumm.then(xummSDK => {
            console.log("Header.js xummSDK", xummSDK.runtime, getTypeFromRuntime(xummSDK.runtime));
            if(getTypeFromRuntime(xummSDK.runtime) === "webapp") {
                xummSDK.environment.openid?.then(r => {
                    console.log('openid Header.js', r)
                    setIdentity(r);
                    setIsAuthorized(true);


                    AccountService.getAccountInfo().then((res) => {
                        console.log("Home account info", res);
                        setAccountInfo(res.data);
                    });
            
                    AccountService.getAccountNfts().then((res) => {
                        console.log("Home account nfts", res);
                        setAccountNfts(res.data);
                    });

                });
            }

            if(getTypeFromRuntime(xummSDK.runtime) === "xapp") {
                xummSDK.environment.ott?.then(r => {
                    console.log('ott Header.js', r);
                    setIdentity(r);
                    setIsAuthorized(true);
                });
            };

            xummSDK.user?.account.then((res) => {
                console.log("Home xumm account", res);
                setAccount(res);
            });


        });

    }, [xumm]);

    const loginXumm = () => {
        xumm?.then(xummSDK => {
            xummSDK.authorize().then((res) => { 
                console.log("authorized", res);   
                // xumm.environment.jwt?.then(r => console.log('jwt', r));
                // res = {jwt: 'eiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfa…4sN9Dxfq-fCtvEjqkpxWjYjafpWZCV47S65kM-jHvH467UmJ3', sdk: XummSdkJwt, me: {…}}
                // you can use the jwt set a bearer 
                // token for your backend and the identity
                if (res.me) {
                    setIdentity(res.me);
                }     
                setIsAuthorized(true);
            }).catch ((err) => {
                console.log("error with auth", err);
            });
        });

    };

    const logoutXumm = () => {
        console.log("logout");
        xumm?.then(xummSDK => {
            xummSDK.logout().then((res) => {
                console.log("logout promise result", res);
                setIsAuthorized(false);
                setIdentity(null);
            }).catch ((err) => {
                console.log("error with logout", err);
            });
        });
    };
 
    return (
      <div className="w-full">        
            <nav className="flex flex-col md:flex-row items-center justify-between bg-slate-700 p-1">
                <div className="flex w-full md:w-1/2 ml-1 mt-1">
                    <div className="ml-2 mt-2 items-center font-semibold text-2xl font-heading tracking-tight
                         text-white" onClick={()=>navigate('/')}>
                        <span className="text-yellow-200">ibis</span><img src={xLogo} 
                            className="w-10 h-8 inline-block"/><span className="text-purple-300">.com</span> 
                    </div>
                </div>
                <div className="flex flex-row md:w-1/2 justify-end w-full">
                    <div>
                        {!isAuthorized && getTypeFromRuntime(runtime) === "webapp" && 
                        <div className="bg-slate-700 flex flex-col justify-start md:flex-row md:justify-end items-center">
                            <div className="rounded p-3 m-2 hover:bg-white hover:cursor-pointer
                             bg-slate-200" onClick={()=>loginXumm()}>
                                <img className="h-4 w-36" src={loginXummLogo}/>
                            </div>                                            
                        </div>}
                    </div>
                </div>   
            </nav>
            {isAuthorized &&
            <div className="bg-slate-700 flex flex-col justify-start md:flex-row md:justify-between items-center p-1">
                <div className="md:ml-1 flex w-full flex-col justify-start md:flex-row md:justify-between p-1">

                    {account && <div className="mr-1 md:mr-3 flex flex-col md:flex-row w-full">
                        <div className="mr-2">Account</div>
                        <div className="text-yellow-500 font-mono text-sm sm:text-sm md:text-lg">{account}</div>
                    </div>}
                    <div className="flex break-words justify-between text-right">
                        {isAuthorized && (runtime) === "xapp" && <> {identity.nodetype} XAPP</>}
                        {isAuthorized && getTypeFromRuntime(runtime) === "webapp" && <> {identity.networkType} BROWSER</>}                
                    </div>
                </div>
                <div className="w-full md:w-fit md:mr-1">            
                    <div className="p-2 btn-common bg-slate-600 text-white hover:bg-slate-500"
                        onClick={logoutXumm}> 
                            {isAuthorized && getTypeFromRuntime(runtime) === "webapp" && <img src={identity.picture} className="w-4 h-4 inline-block ml-1"/>}
                            {isAuthorized && getTypeFromRuntime(runtime) === "xapp" && <img src={`https://xumm.app/avatar/${identity.account}.png`} className="w-4 h-4 inline-block ml-2"/>}            
                            <span className="mr-2 ml-2 md:text-lg">Logout</span>
                    </div>
                </div>             
            </div>}
         
      </div>
    );
};

const dropdownStyle = {
    zIndex: 50
};
  
export default Header