"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.xAppEvents = void 0;
var xAppEvents;
(function (xAppEvents) {
    xAppEvents["scanQr"] = "scanQr";
    xAppEvents["payloadResolved"] = "payloadResolved";
    xAppEvents["selectDestination"] = "selectDestination";
})(xAppEvents = exports.xAppEvents || (exports.xAppEvents = {}));
// export interface xAppActionSelectDestination {
//   // command: selectDestination
// }
// export interface xAppActionScanQr {
//   // command: scanQr
// }
