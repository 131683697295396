//============functions================

/**
 * getTypeFromRuntime
 * @param {*} runtime 
 * @returns 
 */
export function getTypeFromRuntime(runtime) {
    if(runtime.xapp) return "xapp";
    if(runtime.browser && !runtime.xapp) return "webapp";
    return "Unknown";
}
  