import React, {useEffect, useState } from "react"

import { AccountService } from "../service/AccountService";
import Axios from "axios";
import AccountInfo from "../components/AccountInfo";
import AccountNfts from "../components/AccountNfts";
import { Spinner } from "../components/Base";
import { HeroImage, ActionList } from "../components/HomeComponents";

import Page from "../components/Page";


export const Home = ({xumm=null, runtime=null}) => {

    /**
     * we want the app to behave the same depending on if
     * this a XAPP or a webapp
     */
    const [account, setAccount] = useState(null);
    const [accountInfo, setAccountInfo] = useState(null);
    const [accountNfts, setAccountNfts] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(false);


    useEffect(() => {
        xumm?.then(xummSDK => {


            /**
             * IMPORTANT: dont worry about calling this when the api
             * is not available, it just try to call what you need and it will be available after authorization. The promise will be resolved if the api is not available.
             */
            xummSDK.environment.bearer?.then(r => {
                // if you use a backend such as axios, you can set the bearer token here for the default header
                // ie. Axios.defaults.headers.common['Authorization'] = `Bearer ${r}`;
                Axios.defaults.headers.common['Authorization'] = `Bearer ${r}`;
                console.log('bearer Home', r);
                setIsAuthorized(true);
                AccountService.getAccountInfo().then((res) => {
                    console.log("Home account info", res);
                    setAccountInfo(res.data);
                });
        
                AccountService.getAccountNfts().then((res) => {
                    console.log("Home account nfts", res);
                    setAccountNfts(res.data);
                });    

            }).catch((err) => {
                console.log("error with bearer", err);
            });


            xummSDK.user?.account.then((res) => {
                console.log("Home xumm account", res);
                setAccount(res);
            });
        }).catch((err) => {
            console.log("error with xumm", err);
        });

     }, [xumm]);


    return (
        <Page 
            xumm={xumm}
            runtime={runtime}>
            <div>
                {isAuthorized ?
                <div className="p-2">  
                    {accountInfo ? <AccountInfo accountInfo={accountInfo} showJSON={false}/> : 
                        <div><Spinner/> loading account info...</div>}
                    {accountNfts ? <AccountNfts 
                        xumm={xumm}
                        runtime={runtime}
                        accountNfts={accountNfts} showJSON={false}/> : 
                        <div><Spinner/> loading account NFTs...</div>}                                     
                </div>:
                <>  
                    <div>
                        <HeroImage/>
                    </div>
                    <div>
                        <ActionList/>
                    </div>
                </>}
            </div>
        </Page>
    );

    // return (<>
    // <Page xumm={xumm} runtime={runtime}>
    //     YA
    // </Page>
    // </>);
}