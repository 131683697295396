"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Xumm = void 0;
const xumm_oauth2_pkce_1 = require("xumm-oauth2-pkce");
const events_1 = require("events");
/**
 * Development?
 *   npm run watch (tsc in watch mode)
 *   npm run browserify-watch (watch, browserify tsc output)
 *   npm run serve (webserver, :3001)
 *   npm run dev (watch, nodemon dist/samples/index.js)
 *   ngrok start dev (local :3001 to https:// dev URL)
 *     > https://.../sample/
 */
/**
 * TODO: Test:
 *    - Vue
 *    - Vite, SSR
 *    - React
 *    - Remix
 *    - ...
 */
/**
 * Scenarios:
 *   - xApp: apikey:        » Go ahead
 *   - xApp: apikey+secret:             » ERROR
 *   - xApp: jwt:           » Go ahead
 * »»» Load SDKJWT + XAPP (UI INTERACTION)
 *
 *   - Browser: apikey      » PKCE
 *   - Browser: api+secret              » ERROR
 *   - Browser: jwt:        » Go ahead
 * »»» Load SDKJWT
 *
 *   - CLI: apikey                      » ERROR
 *   - CLI: jwt             » Go ahead
 *   - CLI: apikey+secret   » Go ahead
 * »»» Load SDK
 */
var Runtimes;
(function (Runtimes) {
    Runtimes["cli"] = "cli";
    Runtimes["browser"] = "browser";
    Runtimes["xapp"] = "xapp";
})(Runtimes || (Runtimes = {}));
const _runtime = {
    cli: false,
    browser: false,
    xapp: false,
};
const uuidv4re = new RegExp("^[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12}$");
const _classes = {};
const _env = typeof process === "object" && process ? (process === null || process === void 0 ? void 0 : process.env) || {} : {};
Object.assign(_runtime, {
    cli: Object.keys(_env).indexOf("NODE") > -1 ||
        Object.keys(_env).indexOf("SHELL") > -1 ||
        Object.keys(_env).indexOf("TERM") > -1 ||
        Object.keys(_env).indexOf("PATH") > -1,
});
Object.assign(_runtime, {
    browser: (typeof process === "object" && process && (process === null || process === void 0 ? void 0 : process.browser)) ||
        (typeof document === "object" &&
            document &&
            (document === null || document === void 0 ? void 0 : document.location)),
});
Object.assign(_runtime, {
    xapp: _runtime.browser && !!navigator.userAgent.match(/xumm\/xapp/i),
});
const runtime = Object.keys(_runtime).filter((r) => _runtime[r]);
const readyPromises = [];
/**
 * Wrap a function into an awaiter, till the entire object is ready
 */
const Asyncify = (ReturnValue) => __awaiter(void 0, void 0, void 0, function* () {
    yield Promise.all(readyPromises);
    return yield ReturnValue();
});
/**
 * Take an entire constructed class & wrap all responses
 * in a promise (Asyncify) that waits for this entire class
 * to be ready.
 */
const Proxify = (ObjectToProxy) => {
    return new Proxy(ObjectToProxy, {
        get(obj, prop) {
            if (["on", "off"].indexOf(String(prop)) < 0 && // Events
                (typeof obj[prop] === "function" ||
                    obj.constructor.name === "Promise")) {
                return function () {
                    if (obj.constructor.name === "Promise") {
                        return obj.then((res) => Asyncify(() => {
                            return res[prop](...arguments);
                        }));
                    }
                    return Asyncify(() => {
                        return obj[prop](...arguments);
                    });
                };
            }
            return obj[prop];
        },
    });
};
const _initialized = {
    XummSdk: false,
    XummSdkJwt: false,
    XummPkce: false,
    xApp: false,
};
let _ott;
let _jwt = "";
let _jwtData = {};
let _me = {};
let instance = 0;
class UnifiedUserData {
    constructor() {
        this.account = Asyncify(() => { var _a, _b, _c; return (_b = (_a = _jwtData === null || _jwtData === void 0 ? void 0 : _jwtData.sub) !== null && _a !== void 0 ? _a : _me === null || _me === void 0 ? void 0 : _me.sub) !== null && _b !== void 0 ? _b : (_c = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _c === void 0 ? void 0 : _c.account; });
        this.picture = Asyncify(() => {
            var _a, _b, _c, _d, _e, _f, _g;
            return (_a = _me === null || _me === void 0 ? void 0 : _me.picture) !== null && _a !== void 0 ? _a : (((_c = (_b = _jwtData === null || _jwtData === void 0 ? void 0 : _jwtData.sub) !== null && _b !== void 0 ? _b : _me === null || _me === void 0 ? void 0 : _me.sub) !== null && _c !== void 0 ? _c : (_d = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _d === void 0 ? void 0 : _d.account)
                ? `https://xumm.app/avatar/${(_f = (_e = _jwtData === null || _jwtData === void 0 ? void 0 : _jwtData.sub) !== null && _e !== void 0 ? _e : _me === null || _me === void 0 ? void 0 : _me.sub) !== null && _f !== void 0 ? _f : (_g = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _g === void 0 ? void 0 : _g.account}.png`
                : undefined);
        });
        this.name = Asyncify(() => { var _a, _b; return (_a = _me === null || _me === void 0 ? void 0 : _me.name) !== null && _a !== void 0 ? _a : (_b = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _b === void 0 ? void 0 : _b.name; });
        this.domain = Asyncify(() => { var _a, _b; return (_a = _me === null || _me === void 0 ? void 0 : _me.domain) !== null && _a !== void 0 ? _a : (_b = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _b === void 0 ? void 0 : _b.domain; });
        this.source = Asyncify(() => { var _a, _b; return (_a = _me === null || _me === void 0 ? void 0 : _me.source) !== null && _a !== void 0 ? _a : (_b = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _b === void 0 ? void 0 : _b.source; });
        this.networkType = Asyncify(() => {
            var _a, _b;
            return (_b = (_a = _me === null || _me === void 0 ? void 0 : _me.networkType) !== null && _a !== void 0 ? _a : _jwtData.network_type) !== null && _b !== void 0 ? _b : _ott === null || _ott === void 0 ? void 0 : _ott.nodetype;
        });
        this.networkEndpoint = Asyncify(() => {
            var _a, _b;
            return (_b = (_a = _me === null || _me === void 0 ? void 0 : _me.networkEndpoint) !== null && _a !== void 0 ? _a : _jwtData.network_ndpoint) !== null && _b !== void 0 ? _b : _ott === null || _ott === void 0 ? void 0 : _ott.nodewss;
        });
        this.blocked = Asyncify(() => { var _a, _b; return (_a = _me === null || _me === void 0 ? void 0 : _me.blocked) !== null && _a !== void 0 ? _a : (_b = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _b === void 0 ? void 0 : _b.blocked; });
        this.kycApproved = Asyncify(() => { var _a, _b; return (_a = _me === null || _me === void 0 ? void 0 : _me.kycApproved) !== null && _a !== void 0 ? _a : (_b = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _b === void 0 ? void 0 : _b.kycApproved; });
        this.proSubscription = Asyncify(() => { var _a, _b; return (_a = _me === null || _me === void 0 ? void 0 : _me.proSubscription) !== null && _a !== void 0 ? _a : (_b = _ott === null || _ott === void 0 ? void 0 : _ott.account_info) === null || _b === void 0 ? void 0 : _b.proSubscription; });
    }
}
/**
 * This is where the magic happens
 */
class Xumm extends events_1.EventEmitter {
    constructor(apiKeyOrJwt, apiSecretOrOtt) {
        super();
        this.instance = "0";
        this.jwtCredential = false;
        this.runtime = _runtime;
        instance++;
        this.instance = String(instance);
        if (typeof (console === null || console === void 0 ? void 0 : console.log) !== "undefined") {
            console.log("Constructed Xumm", { runtime });
        }
        if (typeof apiKeyOrJwt === "string" &&
            apiKeyOrJwt.split(".").length === 3) {
            this.jwtCredential = true;
            _jwt = apiKeyOrJwt;
        }
        /**
         * Handlers (setup)
         */
        const handleXappEvents = () => __awaiter(this, void 0, void 0, function* () {
            // Always attach event listeners
            // So no:  && !_initialized.xApp
            if (_classes === null || _classes === void 0 ? void 0 : _classes.xApp) {
                _initialized.xApp = true;
                _classes.xApp.on("qr", (data) => {
                    this.emit("qr", data, this.instance);
                });
                _classes.xApp.on("payload", (data) => {
                    this.emit("payload", data, this.instance);
                });
                _classes.xApp.on("destination", (data) => {
                    this.emit("destination", data, this.instance);
                });
            }
        });
        const handlePkceEvents = () => __awaiter(this, void 0, void 0, function* () {
            // Always attach event listeners
            // So no:  && !_initialized.XummPkce
            if (_classes === null || _classes === void 0 ? void 0 : _classes.XummPkce) {
                _initialized.XummPkce = true;
                _classes.XummPkce.on("retrieved", () => {
                    this.emit("retrieved");
                });
                _classes.XummPkce.on("success", () => {
                    this.emit("success");
                });
                _classes.XummPkce.on("error", (data) => {
                    this.emit("error", data);
                });
            }
        });
        /**
         * Xumm SDK
         */
        const handleOttJwt = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if ((_classes === null || _classes === void 0 ? void 0 : _classes.XummSdkJwt) && !_initialized.XummSdkJwt) {
                _initialized.XummSdkJwt = true;
                const doNotFetchJwtOtt = this.jwtCredential || _jwt !== "";
                if (!doNotFetchJwtOtt) {
                    readyPromises.push(_classes.XummSdkJwt.getOttData());
                    readyPromises.push(_classes.XummSdkJwt.getJwt());
                }
                const ott = !doNotFetchJwtOtt
                    ? yield _classes.XummSdkJwt.getOttData()
                    : null;
                const jwt = !doNotFetchJwtOtt
                    ? yield _classes.XummSdkJwt.getJwt()
                    : _jwt;
                if (ott) {
                    _ott = ott;
                    // TODO: DISABLE
                    // console.log("xapp ottdata", _ott);
                }
                if (jwt) {
                    _jwt = jwt;
                    try {
                        _jwtData = JSON.parse(atob((_a = _jwt.split(".")) === null || _a === void 0 ? void 0 : _a[1]));
                        // console.log("pkce/xapp jwtdata", _jwtData);
                    }
                    catch (e) {
                        if (typeof (console === null || console === void 0 ? void 0 : console.log) !== "undefined") {
                            console.log("Error decoding JWT", (e === null || e === void 0 ? void 0 : e.message) || "");
                        }
                    }
                }
                if (typeof (console === null || console === void 0 ? void 0 : console.log) !== "undefined") {
                    // console.log({ ott, jwt });
                }
            }
        });
        const initOttJwtRuntime = () => {
            if (!(_classes === null || _classes === void 0 ? void 0 : _classes.XummSdkJwt)) {
                Object.assign(_classes, {
                    XummSdkJwt: new (require("xumm-sdk").XummSdkJwt)(apiKeyOrJwt),
                });
                readyPromises.push(handleOttJwt());
            }
        };
        if (_runtime.xapp) {
            /**
             * xApp
             */
            if (typeof apiKeyOrJwt !== "string" ||
                !(uuidv4re.test(apiKeyOrJwt) || this.jwtCredential)) {
                throw new Error("Running in xApp, constructor requires first param. to be Xumm API Key or JWT");
            }
            if (!(_classes === null || _classes === void 0 ? void 0 : _classes.xApp)) {
                Object.assign(_classes, {
                    xApp: new (require("xumm-xapp-sdk").xApp)(),
                });
            }
            readyPromises.push(handleXappEvents());
            initOttJwtRuntime();
        }
        else if (_runtime.browser) {
            /**
             * Browser (JWT, PKCE?)
             */
            if (typeof apiKeyOrJwt !== "string" ||
                !(uuidv4re.test(apiKeyOrJwt) || this.jwtCredential)) {
                throw new Error("Running in browser, constructor requires first param. to be Xumm API Key or JWT");
            }
            if (!(_classes === null || _classes === void 0 ? void 0 : _classes.XummPkce)) {
                Object.assign(_classes, {
                    XummPkce: new xumm_oauth2_pkce_1.XummPkce(apiKeyOrJwt, {
                        implicit: true,
                    }),
                });
                if (_classes.XummPkce) {
                    if (this.jwtCredential) {
                        initOttJwtRuntime();
                    }
                    else {
                        const handlePkceState = (resolve) => {
                            var _a, _b;
                            (_b = (_a = _classes.XummPkce) === null || _a === void 0 ? void 0 : _a.state()) === null || _b === void 0 ? void 0 : _b.then((state) => {
                                // state: jwt, me, sdk
                                if ((state === null || state === void 0 ? void 0 : state.sdk) && !(_classes === null || _classes === void 0 ? void 0 : _classes.XummSdkJwt)) {
                                    Object.assign(_classes, { XummSdkJwt: state.sdk });
                                    Object.assign(_me, Object.assign({}, ((state === null || state === void 0 ? void 0 : state.me) || {})));
                                }
                                if ((state === null || state === void 0 ? void 0 : state.jwt) && _jwt === "") {
                                    _jwt = state.jwt;
                                    handleOttJwt();
                                }
                                resolve(state);
                            });
                        };
                        readyPromises.push(handlePkceEvents());
                        readyPromises.push(new Promise((resolve) => {
                            var _a, _b;
                            (_a = _classes.XummPkce) === null || _a === void 0 ? void 0 : _a.on("retrieved", () => {
                                handlePkceState(resolve);
                            });
                            (_b = _classes.XummPkce) === null || _b === void 0 ? void 0 : _b.on("success", () => {
                                handlePkceState(resolve);
                            });
                        }));
                    }
                }
            }
        }
        else if (_runtime.cli) {
            /**
             * CLI, Node / ...
             */
            if (typeof apiKeyOrJwt !== "string") {
                throw new Error("Running CLI, constructor needs first param. to be Xumm API Key / raw JWT");
            }
            else {
                if (uuidv4re.test(apiKeyOrJwt)) {
                    // API Key
                    if (typeof apiSecretOrOtt !== "string" ||
                        !uuidv4re.test(apiSecretOrOtt)) {
                        throw new Error("Running CLI, constructor first param. is API Key, but second param. isn't a valid API Secret");
                    }
                }
                if (!uuidv4re.test(apiKeyOrJwt) &&
                    apiKeyOrJwt.split(".").length !== 3) {
                    throw new Error("Running CLI, constructor first param. not a valid JWT, nor a valid API Key");
                }
                // All fine
                if (!(_classes === null || _classes === void 0 ? void 0 : _classes.XummSdk)) {
                    Object.assign(_classes, {
                        XummSdk: new (require("xumm-sdk").XummSdk)(apiKeyOrJwt, apiSecretOrOtt),
                    });
                }
            }
        }
        /**
         * Bootstrap class properties
         */
        this.user = new UnifiedUserData();
        this.environment = {
            jwt: Asyncify(() => _jwtData),
            ott: Asyncify(() => _ott),
            openid: Asyncify(() => _me),
            bearer: Asyncify(() => _jwt),
        };
        /**
         * Xumm SDK mapped
         */
        this.helpers = Proxify(Asyncify(() => (_classes.XummSdk || _classes.XummSdkJwt)));
        this.push = Proxify(Asyncify(() => (_classes.XummSdk || _classes.XummSdkJwt)
            .Push));
        this.payload = Proxify(Asyncify(() => (_classes.XummSdk || _classes.XummSdkJwt)
            .payload));
        this.userstore = Proxify(Asyncify(() => (_classes.XummSdk || _classes.XummSdkJwt)
            .jwtUserdata));
        this.backendstore = Proxify(Asyncify(() => (_classes.XummSdk || _classes.XummSdkJwt)
            .storage));
        /**
         * Xumm xApp SDK: UI lib.
         */
        const xapp = _classes === null || _classes === void 0 ? void 0 : _classes.xApp;
        if (xapp)
            this.xapp = Proxify(xapp);
    }
    /**
     * PKCE
     */
    authorize() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // console.log("Authorize");
            return (_a = _classes === null || _classes === void 0 ? void 0 : _classes.XummPkce) === null || _a === void 0 ? void 0 : _a.authorize();
        });
    }
    logout() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            Object.assign(_classes, { XummSdkJwt: undefined });
            return (_a = _classes === null || _classes === void 0 ? void 0 : _classes.XummPkce) === null || _a === void 0 ? void 0 : _a.logout();
        });
    }
    /**
     * SDK
     */
    ping() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: What if not JWT but regular?
            yield Promise.all(readyPromises);
            return (_a = ((_classes === null || _classes === void 0 ? void 0 : _classes.XummSdkJwt) || (_classes === null || _classes === void 0 ? void 0 : _classes.XummSdk))) === null || _a === void 0 ? void 0 : _a.ping();
        });
    }
}
exports.Xumm = Xumm;
