import deployment from './deployment.json';

export const xummConfig = {
    name: 'niftyx',
    env: 'production',
    AppId: 'd97f744a-c6d8-4da3-9797-d3782b09e9dd',
    AppSecret: '3a6b7675-fcae-4e3c-a612-6f7c22d9a604',
};

export const appConfig = () => {
    const config = {
        prd: {
            name: 'niftyx',
            env: 'production',
            mintPrice: 1.0,
        },
        dev: {
            name: 'niftyx',
            env: 'development',
            mintPrice: 0.85,
        },
        local: {
            name: 'niftyx',
            env: 'development',
            mintPrice: 0.65,
        }
    };
    return config[deployment.env];
};

export const apiConfig = () => {
    const config = {
        prd: {
            apiBaseUrl: 'https://api.ibisx.com/v1',
            webBaseUrl: 'https://ibisx.com',
            wsBaseUrl: 'wss://ws.ibisx.com',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
        },
        dev: {
            apiBaseUrl: 'http://localhost:3000/v1',
            webBaseUrl: 'http://localhost:3001',
            wsBaseUrl: 'ws://localhost:3002',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
        },
        local: {
            apiBaseUrl: 'http://localhost:5000',
            webBaseUrl: 'http://localhost:1234',
            wsBaseUrl: 'ws://localhost:5002',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
        },
    };
    return config[deployment.env];
};