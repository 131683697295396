import React, {useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Axios from 'axios';


import { Home } from "./pages/Home";

import { Xumm } from "xumm";
import {xummConfig} from "./env";

import "./styles.css";

Axios.defaults.withCredentials = false; 

const make = async () => {
  const xumm = await new Xumm(xummConfig.AppId)

  console.log("====== XUMM runtime", xumm.runtime);

  xumm.environment.bearer?.then(r => {
    console.log("Bearer make", r);
    Axios.defaults.headers.common['Authorization'] = `Bearer ${r}`;
  });

  if (xumm.runtime.xapp) {
      console.log("XAPP");
      xumm.user.account.then(account => document.getElementById('account').innerText = account);

      xumm.xapp.on('destination', data => {
        console.log('A-xapp-destination@', data.destination?.name, data.destination?.address, data?.reason)
      })
      xumm.on('destination', data => {
        console.log('B-xapp-destination@', data.destination?.name, data.destination?.address, data?.reason)
      })
  }

  if (xumm.runtime.browser && !xumm.runtime.xapp) {
      console.log("WEBAPP");
      xumm.on("error", (error) => {
        console.log("error", error)
      })
      xumm.on("success", async () => {
        console.log('success', await xumm.user.account)
      })
      xumm.on("retrieved", async () => {
        console.log("Retrieved: from localStorage or mobile browser redirect", await xumm.user.account)
      })
    }

    return xumm;

};

const xumm = make();


export function App() {

  const [runtime, setRuntime] = useState(false);

  useEffect(() => {

    xumm?.then(xummSDK => {
      console.log("loginXumm xummSDK", xummSDK.runtime);
      const xruntime ={...xummSDK.runtime}
      setRuntime(xruntime);

      xummSDK.environment.bearer?.then(r => {
        // if you use a backend such as axios, you can set the bearer token here for the default header
        // ie. Axios.defaults.headers.common['Authorization'] = `Bearer ${r}`;
        console.log('bearer App', r);
        // Axios.defaults.withCredentials = false; 
        // Axios.defaults.headers.common['Authorization'] = `Bearer ${r}`;
      }).catch((err) => {
          console.log("error with bearer", err);
      });
    });

  }, [xumm]);


  return (
      <>
      <BrowserRouter>
          <Routes>
              <Route path="/xapp" element={
                <Home xumm={xumm} runtime={runtime}/>
              } />  
              <Route path="/" element={
                <Home xumm={xumm} runtime={runtime}/>
              } />  
          </Routes>
      </BrowserRouter>
      </>
  );
}