import React, {useEffect, useState} from "react"
import Axios from 'axios';

import {FaGithubAlt} from "react-icons/fa"
import {IoIosPaper} from "react-icons/io"
import {TbCertificate} from "react-icons/tb"
import {BiSupport} from "react-icons/bi"
import {RxDiscordLogo} from "react-icons/rx"

import {XAppHybridLinkHandler, ExternalLinksViewer} from "./Base";


import { apiConfig } from "../env";

const whitepaperUrl = "https://ibisx.io/whitepaper.pdf";


const Footer = ({
    xumm=null,
    runtime=null,
}) => {

    //${baseUrl}/static/tos.html
    let [apiInfo, setApiInfo] = useState();
    let [baseUrl, setBaseUrl] = useState();

    const externalLinks = [
        { title: 'xApp Deeplink', url: 'https://xumm.app/detect/xapp:sandbox.2575ba43c9eb' },
        { title: 'Terms of Service', url: `${baseUrl}/static/tos.html` },
        { title: 'Privacy Policy', url: `${baseUrl}/static/privacy.html` },
        { title: 'Support', url: 'https://rapaygo.freshdesk.com/support/home' },
        { title: 'Discord', url: 'https://discord.gg/KgjCtPrQ' },
        { title: 'Stable Diffusion Prompt Builder', url: 'https://promptomania.com/stable-diffusion-prompt-builder/' },
    ]

    useEffect(() => {
        if(window.location.port != "80" && window.location.port != "443") {
            console.log("port is not 80 or 443");
            const baseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
            console.log("baseUrl",baseUrl);
            setBaseUrl(baseUrl);

            Axios.get(`${apiConfig().apiBaseUrl}/info`).then((res) => {
                setApiInfo(res.data);
            }).catch((err) => {
                console.log(err);
            });


        } else {
            console.log("port is 80 or 443");
            setBaseUrl(`${window.location.protocol}//${window.location.hostname}`);
        }
    }, []);

    return (
        <>
        <footer className="mt-auto text-white bg-slate-900">
            <div className="p-6 w-full">
                <div className="grid gap-x-0.8 grid-cols-1 md:grid-cols-2"> 

                    <div className="mb-6 sm:w-full md:w-1/2">
                         <h5 className="text-slate-300 font-heading text-2xl"><span className="text-yellow-200">ibis</span><span>X</span><span className="text-purple-300">.com</span></h5>
                         <div className="text-slate-500">by rapaygo LLC</div>
                         <div>The fun and simple way to use Stable Diffusion AI to create, 
                             share and sell NFTs on the XRPL blockchain.</div>
                        {apiInfo ? <div className="text-slate-500">API Version: {apiInfo.version}</div>:<div className="text-red-600">API NOT CONNECTED</div>}
                     </div>
                    <div className="mb-6">
                        <h5 className="mb-2.5 font-heading uppercase text-slate-300">Links</h5>
                        <ExternalLinksViewer xumm={xumm} links={externalLinks}/>
                    </div>
                </div>
            </div>     
            <div className="p-2 text-center">copyright 2022,2023 by rapaygo LLC.</div>     
        </footer>
        </>

    );
  };
  
export default Footer;
