import React, {useEffect, useState} from "react"

import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"

const Page = ({
    withSidenav=false,
    xumm=null,
    runtime=null,
    children,
  }) => {

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex">
          <Header xumm={xumm} runtime={runtime}/>
        </div>
        {withSidenav ? <div id="main" className="flex flex-grow flex-col md:flex-row">
          <div className="flex bg-gray-900  md:bg-gray-800 text-white"><Sidebar/></div>
          <div className="flex-grow">{children}</div>
        </div>:
        <div id="main" className="flex flex-grow flex-col md:flex-row">
            <div className="flex-grow">{children}</div>
        </div>
        }
        <div id="footer" className="p-0 bg-black color-white">
         <Footer xumm={xumm} runtime={runtime}/>
        </div>
      </div>   

    </>
  );
};
  
export default Page