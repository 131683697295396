import React, { useEffect, useState } from "react"
import { Buffer } from "buffer";
import MintNftModal from "./MintNftModal";
import { apiConfig } from "../env";

const AccountNfts = ({
    xumm=null,
    runtime=null,
    accountNfts, 
    showJSON=false}) => {

    const [hasNfts, setHasNfts] = useState(false);

    useEffect(() => {
        if (accountNfts && accountNfts.result && accountNfts.result.account_nfts && accountNfts.result.account_nfts.length) {
            setHasNfts(true);
        }
    }, [accountNfts]);


    function makePinnedGateway(
        URI
      ) {
        const pinnedImage = URI.replace('ipfs://', apiConfig().pinataGateway+"/");
        console.log('pinnedImage',pinnedImage);
        return pinnedImage;
    }
      
    function convertHexToString(
        hex,
        encoding = 'utf8',
      ) {
        const baseUri = Buffer.from(hex, 'hex').toString(encoding);
        return baseUri;
      }

    const NftFromMetadata = ({nftURIHex}) => {
        
        const encoding = 'utf8'

        const [nftURI, setNftURI] = useState(null);
        const [imageURI, setImageURI] = useState(null);

        useEffect(() => {
            const nftURI = Buffer.from(nftURIHex, 'hex').toString(encoding);
            setNftURI(nftURI);
            const parsedURI = new URL(nftURI);
            console.log(parsedURI);
            if (parsedURI.searchParams.get('filename')) {
                console.log(parsedURI.searchParams.get('filename'));
                if (parsedURI.searchParams.get('filename').endsWith('.png')) {
                    setImageURI(makePinnedGateway(nftURI));
                }
            } else { //use the schema to fetch the metadata        
                const pinnedUrl = makePinnedGateway(nftURI);
                console.log('fetching metadata',pinnedUrl);
                fetch(pinnedUrl)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    if (data.image) {
                        setImageURI(makePinnedGateway(data.image));
                    }
                }).catch(error => {
                    console.log(error);
                    setImageURI(makePinnedGateway(nftURI));
                });

            }


        }, [nftURIHex]);


        return (
            <>
                {/* <img className="rounded-lg" src={nftJson.image.replace('ipfs://', apiConfig().pinataGateway)}/>  */}
                {imageURI && <img className="rounded-lg" src={imageURI}/> }
            </>
        );

    }

    const renderNfts = (accountNfts) => {
        return accountNfts.result.account_nfts.map((nft, index) => (
            <div className="flex flex-col w-32 h-32 bg-slate-800 text-yellow-400 m-1 rounded-lg p-1 break-words" key={index}>
                <div className="text-xs font-bold text-yellow-400">
                    <NftFromMetadata nftURIHex={nft.URI}/>
                </div> 
            </div>
        ));
    };

    return (
        <>
            
             <div className="flex flex-col">                
                <div className="flex flex-row text-heading text-2xl justify-between">
                    {hasNfts ? <div>NFTs ({accountNfts.result.account_nfts.length})</div>:<div>No NFTs Yet</div>}   
                    <MintNftModal xumm={xumm} runtime={runtime}/>
                </div>
                {showJSON && <div className="flex flex-row">
                    <code className="w-full"><pre>{JSON.stringify(accountNfts,null,2)}</pre></code>
                </div>}
                {hasNfts ? <div className="flex flex-wrap w-full bg-slate-700 p-1">{renderNfts(accountNfts)}</div>:
                <div className="flex flex-wrap w-full bg-slate-700 p-1">Mint to show NFTs.</div>}
            </div>
        </>
    );
  };
  
export default AccountNfts