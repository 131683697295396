"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.xApp = void 0;
const events_1 = require("events");
const types_1 = require("./types");
__exportStar(require("./types"), exports);
// localStorage.debug = "xapp*";
const docMinAliveSec = 1;
const attemptMs = 250;
const attemptDuration = 2000;
const appStart = Number(new Date());
let documentIsReady;
const documentReadyPromise = new Promise((resolve) => {
    documentIsReady = (value) => {
        console.log("Doc Ready...");
        const timeSinceDocLoad = (Number(new Date()) - appStart) / 1000;
        if (timeSinceDocLoad < docMinAliveSec /* Seconds */) {
            // Stall
            console.log("Doc not alive >= " +
                docMinAliveSec +
                " sec, stalling for " +
                (docMinAliveSec - timeSinceDocLoad));
            setTimeout(function () {
                resolve(value);
            }, (docMinAliveSec - timeSinceDocLoad) * 1000);
        }
        else {
            // Go ahead
            console.log("Doc alive " + docMinAliveSec + "+ sec, go ahead");
            resolve(value);
        }
    };
});
documentReadyPromise
    .then(() => {
    console.log("documentReadyPromise resolved");
})
    .catch((e) => {
    console.log(e);
});
if (typeof document !== "undefined") {
    document.addEventListener("readystatechange", (event) => {
        console.log("(readystatechange: [ " + document.readyState + " ])");
        if (document.readyState === "complete") {
            documentIsReady();
        }
    });
}
if (typeof window !== "undefined") {
    console.log("Loading xApp SDK");
}
let _window = (typeof window !== "undefined" ? window : {});
let isSandbox = false;
if (_window === null || _window === void 0 ? void 0 : _window.parent) {
    // XAPP PROXY
    (_a = _window.parent) === null || _a === void 0 ? void 0 : _a.postMessage("XAPP_PROXY_INIT", "*");
}
const xAppActionAttempt = (command, options, attempt = 0) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    yield documentReadyPromise;
    if (typeof (_window === null || _window === void 0 ? void 0 : _window.ReactNativeWebView) !== "undefined" || isSandbox) {
        const timeSinceDocLoad = (Number(new Date()) - appStart) / 1000;
        if (["close"].indexOf(command) > -1) {
            // Close command awaits app nav state, min sec. alive 4
            const minAliveTimeSec = 4;
            if (timeSinceDocLoad < minAliveTimeSec) {
                console.log("xApp close, doc alive < minAliveTimeSec, stall: " +
                    (minAliveTimeSec - timeSinceDocLoad));
                yield new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(true);
                    }, (minAliveTimeSec - timeSinceDocLoad) * 1000);
                });
            }
        }
        const msgToPost = JSON.stringify(Object.assign({ command }, (options || {})));
        if (isSandbox) {
            (_b = _window.parent) === null || _b === void 0 ? void 0 : _b.postMessage(msgToPost, "*");
        }
        else {
            (_c = _window.ReactNativeWebView) === null || _c === void 0 ? void 0 : _c.postMessage(msgToPost);
        }
        console.log("xAppActionAttempt Success", command, options);
        return true;
    }
    else {
        if (attempt * attemptMs < attemptDuration) {
            // Another attempt
            console.log("xAppActionAttempt Attempt " + attempt + " » Retry", command, options);
            yield new Promise((resolve) => {
                setTimeout(resolve, attemptMs);
            });
            return xAppActionAttempt(command, options, attempt + 1);
        }
        else {
            // Nope
            console.log("xAppActionAttempt Failed after attempt " + attempt, command, options);
            return new Error("xApp." +
                command.replace(/^xApp/, "") +
                ": could not contact Xumm App Host");
        }
    }
});
class xAppThread extends events_1.EventEmitter {
    constructor() {
        super();
        if (document.readyState === "complete") {
            documentIsReady();
        }
        const eventHandler = (event) => {
            const rEvent = event;
            if (typeof (rEvent === null || rEvent === void 0 ? void 0 : rEvent.data) === "string" &&
                rEvent.data === "XAPP_PROXY_INIT_ACK") {
                console.log("xApp Proxy ACK received, switching to PROXY (SANDBOX) mode");
                isSandbox = true;
                return;
            }
            try {
                const _event = JSON.parse((rEvent === null || rEvent === void 0 ? void 0 : rEvent.data) || "{}");
                console.log({ _event });
                if (typeof _event === "object" && _event !== null) {
                    if (typeof _event.method === "string" &&
                        _event.method in types_1.xAppEvents) {
                        console.log("xApp Event received", _event.method, _event);
                        const method = _event.method;
                        delete _event.method;
                        switch (method) {
                            case String(types_1.xAppEvents.payloadResolved):
                                this.emit("payload", _event);
                                break;
                            case String(types_1.xAppEvents.scanQr):
                                this.emit("qr", _event);
                                break;
                            case String(types_1.xAppEvents.selectDestination):
                                this.emit("destination", _event);
                                break;
                        }
                    }
                    else {
                        console.log("xApp Event received, not in xAppEvents", _event.method, types_1.xAppEvents);
                    }
                }
            }
            catch (e) {
                const emessage = (e === null || e === void 0 ? void 0 : e.message) || "";
                if (!emessage.match(/XAPP_PROXY_INIT/)) {
                    console.log("xApp Event received, cannot parse as JSON", emessage);
                }
            }
        };
        if (typeof window.addEventListener === "function") {
            window.addEventListener("message", eventHandler);
        }
        if (typeof document.addEventListener === "function") {
            document.addEventListener("message", eventHandler);
        }
    }
    navigate(navigateOptions) {
        if (typeof (navigateOptions === null || navigateOptions === void 0 ? void 0 : navigateOptions.xApp) !== "string") {
            return Promise.reject(new Error("xApp.navigate: Invalid argument: `xApp`"));
        }
        return xAppActionAttempt("xAppNavigate", navigateOptions);
    }
    openSignRequest(openSignRequestOptions) {
        if (typeof (openSignRequestOptions === null || openSignRequestOptions === void 0 ? void 0 : openSignRequestOptions.uuid) !== "string") {
            return Promise.reject(new Error("xApp.openSignRequest: Invalid argument: `uuid`"));
        }
        if (!openSignRequestOptions.uuid.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)) {
            return Promise.reject(new Error("xApp.openSignRequest: Invalid payload UUID"));
        }
        return xAppActionAttempt("openSignRequest", openSignRequestOptions);
    }
    selectDestination() {
        return xAppActionAttempt("selectDestination");
    }
    openBrowser(openBrowserOptions) {
        if (typeof (openBrowserOptions === null || openBrowserOptions === void 0 ? void 0 : openBrowserOptions.url) !== "string") {
            return Promise.reject(new Error("xApp.openBrowser: Invalid argument: `url`"));
        }
        return xAppActionAttempt("openBrowser", openBrowserOptions);
    }
    share(shareOptions) {
        if (typeof (shareOptions === null || shareOptions === void 0 ? void 0 : shareOptions.url) !== "string" &&
            typeof (shareOptions === null || shareOptions === void 0 ? void 0 : shareOptions.title) !== "string" &&
            typeof (shareOptions === null || shareOptions === void 0 ? void 0 : shareOptions.text) !== "string") {
            return Promise.reject(new Error("xApp.openBrowser: Invalid argument: `title` / `text` / `url`"));
        }
        return xAppActionAttempt("share", shareOptions);
    }
    scanQr() {
        return xAppActionAttempt("scanQr");
    }
    tx(txOptions) {
        if (typeof (txOptions === null || txOptions === void 0 ? void 0 : txOptions.tx) !== "string") {
            return Promise.reject(new Error("xApp.tx: Invalid argument: `tx`"));
        }
        if (typeof (txOptions === null || txOptions === void 0 ? void 0 : txOptions.account) !== "string") {
            return Promise.reject(new Error("xApp.tx: Invalid argument: `account`"));
        }
        return xAppActionAttempt("txDetails", txOptions);
    }
    close(closeOptions) {
        return xAppActionAttempt("close", closeOptions);
    }
    customCommand(customCommand, customCommandOptions) {
        return xAppActionAttempt(customCommand, customCommandOptions);
    }
}
const thread = (_xApp) => {
    let attached = false;
    if (_xApp) {
        if (typeof _window === "object") {
            if (typeof _window._xAppSdk === "undefined") {
                _window._xAppSdk = _xApp;
                attached = true;
            }
        }
    }
    const instance = _window === null || _window === void 0 ? void 0 : _window._xAppSdk;
    if (instance && attached) {
        console.log("xAppSdk attached to window");
    }
    return instance;
};
class xApp {
    constructor() {
        if (typeof window === "undefined" || typeof document === "undefined") {
            return;
        }
        if (!thread()) {
            thread(new xAppThread());
        }
    }
    on(event, listener) {
        const t = thread();
        if (!t) {
            return;
        }
        t.on(event, listener);
        return this;
    }
    off(event, listener) {
        const t = thread();
        if (!t) {
            return;
        }
        t.off(event, listener);
        return this;
    }
    navigate(navigateOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.navigate(navigateOptions);
    }
    openSignRequest(openSignRequestOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.openSignRequest(openSignRequestOptions);
    }
    selectDestination() {
        const t = thread();
        if (!t) {
            return;
        }
        return t.selectDestination();
    }
    openBrowser(openBrowserOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.openBrowser(openBrowserOptions);
    }
    share(shareOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.share(shareOptions);
    }
    scanQr() {
        const t = thread();
        if (!t) {
            return;
        }
        return t.scanQr();
    }
    tx(txOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.tx(txOptions);
    }
    close(closeOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.close(closeOptions);
    }
    customCommand(customCommand, customCommandOptions) {
        const t = thread();
        if (!t) {
            return;
        }
        return t.customCommand(customCommand, customCommandOptions);
    }
}
exports.xApp = xApp;
